import React from 'react'
import { motion } from 'framer-motion';
import { Link } from "gatsby"

const ResourcesMenu = ({ downloads, activeSection }) => {
  return (
    <ul id="resources-menu" className="mt-4 lg:sticky lg:top-20">
        <li className="mb-1">
          <Link className="resource-link" activeClassName="active" to="/resources/materials">Materials</Link>
          <ul className="flex flex-col mt-2 ml-3">
          <li className="mb-1"><Link title={`Papers`} className={`resource-link ${activeSection === 'papers' ? 'active' : 'opacity-50 hover:opacity-100'}`} to={`/resources/materials#anchor-papers`}>Papers</Link></li>
          <li className="mb-1"><Link title={`Woven`} className={`resource-link ${activeSection === 'woven' ? 'active' : 'opacity-50 hover:opacity-100'}`} to={`/resources/materials#anchor-woven`}>Woven</Link></li>
          <li className="mb-1"><Link title={`Textures`} className={`resource-link ${activeSection === 'textures' ? 'active' : 'opacity-50 hover:opacity-100'}`} to={`/resources/materials#anchor-textures`}>Textures</Link></li>
          <li className="mb-1"><Link title={`Foils`} className={`resource-link ${activeSection === 'foils' ? 'active' : 'opacity-50 hover:opacity-100'}`} to={`/resources/materials#anchor-foils`}>Foils</Link></li>
          </ul>
        </li>
        <li className="hidden mb-1"><Link  className="resource-link"activeClassName="active" to="/resources/product-styles">Product styles</Link></li>
        <li className="hidden mb-1">
          <Link className="resource-link" activeClassName="active" to="/resources/downloads">Downloads</Link>
          {downloads.edges > 0 && (
            <motion.ul className="flex flex-col mt-2 ml-3">
            {downloads.edges.map((item) => {
              const {title, slug, resources} = item.node
              return (
                <>
                {resources[0] && (
                  <li className="mb-1"><Link title={title} className={`resource-link ${activeSection === slug.current ? 'active' : 'opacity-50 hover:opacity-100'}`} to={`/resources/downloads#anchor-${slug.current}`}>{title}</Link></li>
                )}
                </>                
              )
            })}
          </motion.ul>
          )}          
          </li>
        <li className="mb-1"><Link className="resource-link" activeClassName="active" to="/resources/guidelines">Guidelines</Link></li>
        <li className="mb-1"><Link className="resource-link" activeClassName="active" to="/resources/glossary">Glossary</Link></li>
        <li className="mb-1"><Link className="resource-link" activeClassName="active" to="/resources/faq">FAQ</Link></li>
    </ul>
  )
}

export default ResourcesMenu