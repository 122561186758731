import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { GatsbyImage } from "gatsby-plugin-image"

// Components
import { PortableTextBlock } from "../../components/ui/portableTextBlock"

const MaterialCard = ({ className,  title, imagePrimary, description }) => {
  
    const [showDescription, setShowDescription] = useState(false)    

    function handleClick() { 
        setShowDescription(!showDescription)
      }        

      return (
        <div className={`material_card rounded-lg overflow-hidden text-base relative flex items-end aspect-square ${className}`}>
            {imagePrimary && (
               <div className="absolute bottom-0 left-0 right-0 top-0 pointer-events-none">
                <GatsbyImage          
               image={imagePrimary.asset.gatsbyImageData} 
               alt={imagePrimary.asset.alt}                                
               className="z-0 w-full h-full object-contain overflow-hidden img-rounded-lg xl:transform xl:hover:scale-125 xl:transition xl:duration-1000 pointer-events-none"                
           />    
               </div>
            )}
            <div className="w-full px-3 py-4 z-20 bg-white rounded-b-lg">
            {description ? (
                <>
                <div
                    onClick={handleClick}
                    role="button"
                    tabIndex="0"                 
                    aria-label="Show details"                    
                    className={`group flex justify-between items-baseline text-sm ${showDescription ? 'cursor-s-resize' : 'cursor-n-resize'}`}                
                >
                    <div>{title && title}</div>  
                    <span className="opacity-40 xl:group-hover:opacity-100 trans-std">{showDescription ? 'Close' : 'Details'}</span>
                </div>                            
                
                <motion.div        
                    initial={{ opacity: '0', height: '0px'}}                                            
                    animate={{ opacity: showDescription ? '100%' : '0', height: showDescription ? 'auto' : '0px' }}                    
                    transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                    }}
                >
                    
                    <PortableTextBlock className="py-2 material-desc" text={description} />
                
                </motion.div>                
                </>
            ) : (
                
                <div className="flex justify-between items-baseline text-sm">                    
                    {title && <div>{title}</div>}                      
                </div>  

            )}
            </div>
        </div>
      )

}

export default MaterialCard