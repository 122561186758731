import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from 'framer-motion';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useResourceInfo } from '../GraphQl/useResourceInfo';

// Components
import Layout from '../components/layout';
import Seo from '../components/global/seo';
import { PortableTextBlock } from "../components/ui/portableTextBlock"

import ResourcesMenu from "../components/resources/resourcesMenu"
import MaterialSection from "../components/resources/materialSection"  
import DownloadsSection from "../components/resources/downloadsSection"  
import Glossary from "../components/resources/glossary"  
import Faq from '../components/resources/faq';

const ResourcePageTemplate = ({ data: { page, papers, foils, textures, wovens, glossaryTerms, downloads, guidelines } }) => {

  const { 
    papersTitle,
    papersDesc,
    wovenTitle,
    wovenDesc,
    texturesTitle,
    texturesDesc,
    foilsTitle,
    foilsDesc
   } = useResourceInfo()
  
  const { 
    title, 
    briefUsCta, 
    description, 
    resourceContent, 
    faqContent,
    seo 
  } = page || {}   

  const { 
    setMyPorter, 
    setFooterHidden, 
    setHeaderDark, 
    setShowingBriefUs, 
    setFooterForms, 
    setFooterTheme 
  } = useSiteState();  

  const [activeSection, setActiveSection] = useState();

  useEffect(() => {  
    document.documentElement.setAttribute('data-theme', `theme-grey-70`);
    document.documentElement.style.setProperty(`--doc-bg`, '#B3B3B3');      
    setMyPorter(false)
    setHeaderDark(true)
    setShowingBriefUs(briefUsCta)
    setFooterForms(false)
    setFooterTheme('theme-none')
    setFooterHidden(false)
  });

  

  return (
    <Layout className="scroll-smooth">     
       
       {seo && <Seo {...seo} />}
       
       <div className="block w-full h-header"></div>        
       
        <div id="pp-resources">
            
            <motion.div
              className="section-title"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
            >Resources</motion.div>

            <motion.div
              className="section-sidebar"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
             >

              <div className="lg:hidden text-md">Resources</div>

              <ResourcesMenu papers={papers} downloads={downloads} activeSection={activeSection} />

            </motion.div>

            <motion.div
              className="section-content"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
            >
                
                <h1 className="page-title">{title && title}</h1>

                <div className="page-description">
                    {description && <PortableTextBlock text={description} />}
                </div>

                {/* Materials */}
                {resourceContent === "Materials" && (
                  <>
                  <MaterialSection activeSection={activeSection} setActiveSection={setActiveSection} id="papers" title={papersTitle ? papersTitle : 'Papers'} items={papers} description={papersDesc} />
                  <MaterialSection activeSection={activeSection} setActiveSection={setActiveSection} id="woven" title={wovenTitle ? wovenTitle : 'Woven'} items={wovens} description={wovenDesc} />
                  <MaterialSection activeSection={activeSection} setActiveSection={setActiveSection} id="textures" title={texturesTitle ? texturesTitle : 'Textures'} items={textures} description={texturesDesc}/>
                  <MaterialSection activeSection={activeSection} setActiveSection={setActiveSection} id="foils" title={foilsTitle ? foilsTitle : 'Foils'} items={foils} description={foilsDesc}/>
                  </>
                )}

                {/* Glossary */}
                {resourceContent === "Glossary" && (
                  <Glossary glossaryTerms={glossaryTerms}/>
                )}

                {/* Guidelines */}
                {resourceContent === "Guidelines" && (
                  <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-y-20 mb-20">
                    {guidelines.items.map((item) => {
                      const {title, caption, image} = item
                      return (
                          <li>
                              {image && (
                                  <GatsbyImage          
                                  image={image.asset.gatsbyImageData} 
                                  alt={image.asset.alt} 
                                  className="w-full h-auto mb-5"                
                                />    
                              )}
                              {title && <div className="mb-3">{title}</div>}
                              {caption && <PortableTextBlock className="text-sm" text={caption} />}
                          </li>
                      )
                    })}
                  </ul>
                )}

                {/* Downloads */}
                {resourceContent === "Downloads" && (
                  <DownloadsSection downloads={downloads} setActiveSection={setActiveSection} />
                )}

                {/* Product Styles */}
                {resourceContent === "Product styles" && (
                  <>
                  <div className="text-2xl">Product Styles WIP</div>
                  </>
                )}

                {/* FAQ */}
                {resourceContent === "FAQ" && (
                  <Faq items={faqContent}/>
                )}

            </motion.div>

        </div>
        
    </Layout>
  );
};

export default ResourcePageTemplate;

export const pageQuery = graphql`
  query resourcePageQuery($slug: String!) {
    page: sanityPageResource(slug: { current: { eq: $slug } }) {
      title 
      briefUsCta           
      slug {
          current
      }
      seo {
        ...SEO
      }
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      resourceContent  
      faqContent {
        _key    
        title        
        rowContent {          
          heading          
          text: _rawContent(resolveReferences: {maxDepth: 10})
        }        
      }
    }
    downloads: allSanityProduct {
      edges {
        node {
          title
          slug {
            current
          }
          description: _rawResourceDescription(resolveReferences: {maxDepth: 10})
          resources {
            name
            file {
              asset {
                url
              }
              _type
            }
          }
          resourceImage {
            asset {
                gatsbyImageData
            }
        }
        }
      }
    }
    papers: allSanityPaper {
      edges {
        node {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
              asset {
                  gatsbyImageData
              }
          }
        }
      }
    }
    textures: allSanityTexture {
        edges {
          node {
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary {
                asset {
                    gatsbyImageData
                }
            }
          }
        }
    }
    wovens: allSanityWoven {
      edges {
        node {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
              asset {
                  gatsbyImageData
              }
          }
        }
      }
  }
    foils: allSanityFoil {
        edges {
          node {
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary {
                asset {
                    gatsbyImageData
                }
            }
          }
        }
    }
    glossaryTerms: allSanityTerm(sort: {fields: title}) {
      edges {
        node {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
        }
      }
    }
    guidelines: sanityGuidelines {
      title
      description: _rawDescription(resolveReferences: {maxDepth: 10})
      items {
          title
          caption: _rawCaption(resolveReferences: {maxDepth: 10})
          image {
              asset {
                  gatsbyImageData
              }
          }
      }
  }
  }
`;
 