import React, { useState } from 'react'
import { PortableTextBlock } from "../ui/portableTextBlock"
import { AnimatePresence, motion } from 'framer-motion'

//Animation
import { accordionVariants } from '../../lib/animation';

const FaqSection = ({ heading, text }) => {

    const [isOpened, setIsOpened] = useState(false);
  
    return (
      <div className={`border-b border-current overflow-hidden`}>
      <button 
        className="w-full flex justify-between items-baseline py-2"
        onClick={() => setIsOpened(!isOpened)}
      >
            <span className="text-left">{heading}</span>
            <span>{isOpened ? '-' : '+'}</span>
          </button>
          
          <AnimatePresence exitBeforeEnter>
            {isOpened && (
              <motion.div
                variants={accordionVariants}
                initial="closed"
                animate="open"
                exit="closed"
              >
                <div className="py-4">
                {text && <PortableTextBlock className="prose measure" text={text} />}
                </div>
              </motion.div>
            )}
            </AnimatePresence>       
      </div>
    )
  }

const Faq = ({ items }) => {
  return (
    <div className="grid grid-cols-1 grid-flow-row gap-y-12 mb-20">
        {items.map((item => {
            return (
                <div className={`grid grid-cols-1 grid-flow-row gap-y-6`}>
                    {item.title && 
                    <div className={`col-span-full`}>
                        <p className="text-md lg:text-lg lg:max-w-6xl">{item.title}</p>
                    </div>
                    }                            
                    {items && <div className={`col-span-full`}>
                        <section className="grid grid-cols-1 gap-6 lg:gap-8 grid-flow-row">
                            {item.rowContent.map((content) => (
                            <motion.div
                            className="col-span-1"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true, amount: 0.5 }}
                            >
                                <FaqSection {...content} />                 
                            </motion.div>
                            ))}   
                            </section>
                    </div>}        
                </div>
            )
        }))}
    </div>
  )
}

export default Faq