import React, { useState } from 'react';
import { motion } from 'framer-motion';

// Components
import { PortableTextBlock } from "../ui/portableTextBlock"

const GlossaryTerm = ({ title, description }) => {

    const [showDescription, setShowDescription] = useState(false)    

    function handleClick() { 
        setShowDescription(!showDescription)
      }
  
      function handleKeyDown(e) {
        if (e.keyCode === 13) {
          handleClick();
        }
      }

    return (
        <div className="mb-1">
            <button
            className={`appearance-none trans-std hover:opacity-100 ${!showDescription && 'opacity-50'}`}
                aria-label={`Show description for ${title}`}            
                onKeyDown={handleKeyDown}                      
                onClick={handleClick}
                type="button"            
                tabIndex="0"              
            >
                {title && title}
            </button>
            {description && (
                <motion.div  
                    className="overflow-hidden"       
                    style={{ opacity: 0, height: '0px' }}                                           
                    animate={{ opacity: showDescription ? '100%' : '0', height: showDescription ? 'auto' : '0px', pointerEvents: showDescription ? 'all' : 'none' }}
                    transition={{ type: "spring", bounce: 0 }}            
                >
                    <PortableTextBlock className="py-2 material-desc max-w-lg" text={description} />
                </motion.div>
            )}
        </div>
    )
}

export default GlossaryTerm;