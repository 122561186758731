import { useStaticQuery, graphql } from 'gatsby';

export const useResourceInfo = () => {
  const { sanityResourceDescriptions } = useStaticQuery(graphql`
    query {
        sanityResourceDescriptions {
        papersTitle
        papersDesc: _rawPapersDesc(resolveReferences: {maxDepth: 10})
        wovenTitle
        wovenDesc: _rawWovenDesc(resolveReferences: {maxDepth: 10})
        texturesTitle
        texturesDesc: _rawTexturesDesc(resolveReferences: {maxDepth: 10})
        foilsTitle
        foilsDesc:  _rawFoilsDesc(resolveReferences: {maxDepth: 10})
        }
    }    
  `);
  return sanityResourceDescriptions || {};
};
