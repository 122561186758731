import React, { useState } from 'react'

//Components
import GlossaryTerm from "../resources/glossaryTerm"  

const Glossary = ({ glossaryTerms }) => {

    const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

    //Search  
    const [searchItems, setSearchItems] = useState(glossaryTerms.edges)
    const arraySearch = (array, keyword) => {
        const searchTerm = keyword.toLowerCase()
        return array.filter(value => {
            return value.node.title.toLowerCase().match(new RegExp(searchTerm, 'g'))
        })
    }

    const handleSearch = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
        let search = await arraySearch(glossaryTerms.edges, value);
        setSearchItems(search)
        } else {
        setSearchItems(glossaryTerms.edges)
        }
    }

    return (
    <div>
        <div className="mb-8">
            <input type="text" placeholder={`Search terms`} className="appearance-none p-1 border-b border-pp-grey30 bg-transparent placeholder:text-pp-grey50 text-lg outline-none"  onChange={handleSearch} />
        </div>
        <ul className="lg:columns-2 mb-12">
            {alphabet.map((letter) => {
                let letterIsVisible = false;
                searchItems.map((term) => (
                    term.node.title.charAt(0) === letter && (letterIsVisible = true)
                ))                                
            return (
                letterIsVisible === true && (
                    <div className="w-full flex lg:flex-row mb-4">
                    <div className="w-1/3 lg:w-1/6 text-2xl lg:text-2 lg:mr-2 leading-none">{letter}</div>                                                                
                    <div className="w-2/3 lg:w-5/6">
                        {searchItems.map((term) => (
                            <>
                            {term.node.title.charAt(0) === letter && (
                                <GlossaryTerm {...term.node} />
                            )} 
                            </>
                        ))}
                        </div>
                    </div>
                )                
                )
            })}
        </ul>
    </div>
  )
}

export default Glossary