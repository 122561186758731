import React, { useState, useEffect } from 'react'
import { useWindowWidth } from '@react-hook/window-size'
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

//Components
import MaterialCard from "../ui/materialCard"
import { PortableTextBlock } from "../ui/portableTextBlock"

//SVG
import SmallGridIcon from '../../svg/nineItemGrid.svg';
import LargeGridIcon from '../../svg/fourItemGrid.svg';

//Animation
import { materialSectionVariants } from '../../lib/animation';

const MaterialSection = ({ id, title, description, items, activeSection, setActiveSection }) => {

  const windowWidth = useWindowWidth()

  //Pagination
  const itemsPerPage = 6;  
  const [itemsToShow, setItemsToShow] = useState([]);
  const [next, setNext] = useState(6);
  const [largeGrid, setLargeGrid] = useState(false);

  const loopWithSlice = () => {
    const toShow = items.edges.slice(
      itemsToShow.length,
      itemsToShow.length + itemsPerPage
    );
    setItemsToShow([...itemsToShow, ...toShow]);
  };

  useEffect(() => {
    if (items.edges) {
      loopWithSlice();
    }
  }, []);

  const handleShowMorePosts = () => {
    let loadedMore = next + itemsPerPage;
    loopWithSlice(next, loadedMore);
    setNext(next + itemsPerPage);
  };


  //SubMenu Activation
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });  

  useEffect(() => {
    if (inView) {      
      setActiveSection(id);
    }    
  }, [id, setActiveSection, inView]);

  //Search  
  const [searchItems, setSearchItems] = useState(items.edges)
  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    return array.filter(value => {
        return value.node.title.toLowerCase().match(new RegExp(searchTerm, 'g'))
    })
}


const handleSearch = async (e) => {
    let value = e.target.value;
    if (value.length > 2) {
      let search = await arraySearch(items.edges, value);
      setSearchItems(search)
    } else {
      setSearchItems(items.edges)
    }
  }

  return (
    <div ref={ref}>
    <h2 id={`anchor-${id}`} className="text-md lg:text-lg mb-4 lg:pt-8">{title && title}</h2>
    <div className="prose mb-8 lg:mb-12 max-w-xl">{description && <PortableTextBlock text={description} />}</div>
    <div className="mb-gutter hidden lg:grid lg:grid-cols-20">
      
      <div className="col-span-4 lg:col-start-1 lg:col-end-6">
        <input 
          type="text" 
          placeholder={`Search ${title && title}...`} 
          className="outline-none appearance-none p-1 border border-pp-grey30 bg-transparent placeholder:text-pp-grey50 input-icon-search"  
          onChange={handleSearch} 
        />
      </div>
      
      <div className="col-span-4 lg:col-start-15 lg:col-end-21">
        
        <div className={`flex gap-x-4 justify-end`}>
          
          <div 
            role="button" 
            tabIndex="0"
            className={largeGrid ? 'opacity-60' : undefined}
            onClick={() => setLargeGrid(false)} 
          >
            <SmallGridIcon />
          </div>
          
          <div
            role="button" 
            tabIndex="0"
            className={!largeGrid && 'opacity-60'}
            onClick={() => setLargeGrid(true)} 
          >
            <LargeGridIcon />
          </div>
        
        </div>      
      
      </div>

    </div>    
    
      <div className="mb-16 lg:hidden flex overflow-x-scroll hide-scrollbar">
        {itemsToShow.map((item, index) => (        
            <MaterialCard className="flex-none w-4/5 mr-4" {...item.node} />        
        ))}
      </div>
    
    
      <AnimatePresence exitBeforeEnter>
        <motion.ul 
          className={`hidden lg:grid grid-cols-1 gap-gutter mb-16 lg:mb-32 ${largeGrid ? 'md:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-3'}`}
          variants={materialSectionVariants}
          key={largeGrid}
          initial="exit"
          animate="enter"
          exit="exit"      
          >
          {searchItems < items.edges ? (
            searchItems.map((item) => {return (<li className="col-span-1"><MaterialCard {...item.node} /></li>)})
          ) : (
            itemsToShow.map((item) => {return (<li className="col-span-1"><MaterialCard {...item.node} /></li>)})
          )}        
        <li className="col-span-full text-xl">
          <div>{`1 - `}{itemsToShow.length}{` / `}{items.edges.length}</div>
          <div 
            className={`
              text-pp-grey50 cursor-pointer trans-std hover:text-current 
              ${itemsToShow >= items.edges && 'hidden'}`
            }
            role="button"
            tabIndex="0"
            onClick={handleShowMorePosts}
          >
          Show more</div>
        </li>
      </motion.ul>   
    </AnimatePresence>    
    </div>
  )
}

export default MaterialSection