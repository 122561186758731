import React, { useEffect } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer';

import { PortableTextBlock } from "../ui/portableTextBlock"


const DownloadItem = ({ setActiveSection, title, description, resources, resourceImage, slug }) => {

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: false,
  });  


  useEffect(() => {
    if (inView) {      
      setActiveSection(slug.current);
    }    
  }, [setActiveSection, inView, slug]);

  return (
    <>
    {resources[0] && (
            <li ref={ref} id={`anchor-${slug.current}`} className="col-span-1 grid grid-cols-1 grid-flow-row md:grid-cols-2 gap-y-4 md:gap-x-6 lg:gap-x-gutter lg:grid-cols-20">
              <div className="col-span-1 lg:col-start-9 lg:col-end-21">
                {resourceImage && resourceImage.asset && (
                    <GatsbyImage          
                    image={resourceImage.asset.gatsbyImageData} 
                    alt={resourceImage.asset.alt} 
                    className="w-full h-auto mb-2 md:mb-0"                
                  />    
                )}
              </div>
              <div className="col-span-1 lg:col-start-1 lg:col-end-9 lg:row-start-1 lg:pb-16">
                <div className="lg:sticky lg:top-24">
                {title && <div className="text-lg block mb-4">{title}</div>}
                {description && <PortableTextBlock className="prose mb-6" text={description} />}  
                <div className="flex gap-x-2">
                  {resources.map((resource) => {
                    const {name, file} = resource
                    return (
                      <a target="_blank" rel="noreferrer" className="btn-icon" href={file ? file.asset.url : ''}>
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 12.1818L6.662 12.6776C6.86589 12.8166 7.13411 12.8166 7.338 12.6776L7 12.1818ZM7.6 4C7.6 3.66863 7.33137 3.4 7 3.4C6.66863 3.4 6.4 3.66863 6.4 4H7.6ZM3.338 8.95881C3.06421 8.77214 2.69094 8.84276 2.50426 9.11654C2.31759 9.39033 2.38821 9.76361 2.662 9.95028L3.338 8.95881ZM11.338 9.95028C11.6118 9.76361 11.6824 9.39033 11.4957 9.11654C11.3091 8.84276 10.9358 8.77214 10.662 8.95881L11.338 9.95028ZM3 13.4C2.66863 13.4 2.4 13.6686 2.4 14C2.4 14.3314 2.66863 14.6 3 14.6L3 13.4ZM11 14.6C11.3314 14.6 11.6 14.3314 11.6 14C11.6 13.6686 11.3314 13.4 11 13.4V14.6ZM7.6 12.1818V4H6.4V12.1818H7.6ZM2.662 9.95028L6.662 12.6776L7.338 11.6861L3.338 8.95881L2.662 9.95028ZM7.338 12.6776L11.338 9.95028L10.662 8.95881L6.662 11.6861L7.338 12.6776ZM3 14.6H11V13.4L3 13.4L3 14.6Z" className="fill-current"/></svg>
                      <span>{name ? name : 'Download' }</span>                                    
                      </a>  
                    )
                  })}                                    
                </div>
                </div>                              
              </div>                              
          </li>
          )}
    </>
  )
}
const DownloadsSection = ({ downloads, setActiveSection }) => {

  return (
    <ul className="w-full grid grid-cols-1 grid-flow-row gap-y-12 md:gap-y-0 mb-24">
    {downloads.edges.map((item) => {
        return (<DownloadItem setActiveSection={setActiveSection} {...item.node} /> )
      })}
    </ul>
  )
}

export default DownloadsSection